import billAndMelindaGates from 'src/assets/partners/bill-and-melinda-gates-foundation.png';
import ccHub from 'src/assets/partners/cchub-logo.jpg';
import edoGov from 'src/assets/partners/edo-government.png';
import edoInnovate from 'src/assets/partners/Edo-innovate-Logo.jpg';
import styled from 'styled-components';

const PARTNERS = [
  {
    name: 'Edo State Government',
    logo: edoGov,
  },
  {
    name: 'Bill And Melinda Gates Foundation',
    logo: billAndMelindaGates,
  },
  {
    name: 'Edo Innovate',
    logo: edoInnovate,
  },
  {
    name: 'CC Hub',
    logo: ccHub,
  },
];

const PartnerSection = () => {
  return (
    <StyledPartnerSection>
      <h2>Awards & Partners</h2>

      <div>
        {PARTNERS.map(partner => (
          <img src={partner.logo} alt={partner.name} key={partner.name} />
        ))}
      </div>
    </StyledPartnerSection>
  );
};

export default PartnerSection;

const StyledPartnerSection = styled.div`
  width: ${props => props.theme.width.container};
  max-width: ${props => props.theme.width.max};
  margin-inline: auto;
  padding-bottom: 2rem;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 4rem;
  }

  div {
    max-width: 780px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
      max-height: 80px;
      width: 150px;
      object-fit: contain;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 4rem;

    h2 {
      font-size: 2rem;
    }

    div {
      flex-direction: row;
    }
  }
`;
