import Layout from 'src/components/Layout';
import {
  // AvatarGroup,
  // AvatarImage,
  Hero,
  HeroContainer,
  HeroHeading,
  // HeroImage,
  HeroImages,
  HeroText,
  ListItem,
  ServiceBanner,
  ServiceBannerHeading,
  ServiceBannerImg,
  ServiceBannerList,
  ServiceBannerText,
  ServiceCards,
  ServiceHeading,
  ServiceHeadingGroup,
  ServiceSubHeading,
  Services,
  // ServicesIntro,
  ServicesText,
  PortfolioContainer,
  // Card,
  // TestimonialGrid,
  // TestimonialSlider,
  // Testimonials,
  // HeroImagesLabel,
  TextGroup,
  PortfolioCardsWrapper,
  PortfolioText,
  PortfolioHeading,
  // HeroImagesLabelWrapper,
} from './style';
import Button from 'src/components/Button';
import {
  HERO_DETAILS,
  PORTFOLIO_DETAILS,
  // HERO_IMAGES,
  SERVICES_DETAILS,
  // TESTIMONIAL_CARDS,
  // TESTIMONIAL_DETAILS,
} from 'src/constants/landing';
import { DoubleCheckMark, RightArrow } from 'src/components/Icons';
import ServiceCard from 'src/components/ServiceCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ServicesSlideProps,
  // TestimonialSlideProps
} from 'src/utils/swiper';
// import TestimonialCard from "src/components/TestimonialCard";
// import SliderControl from "src/components/SliderControl";
import {
  containerVariants,
  getScrollRevealProps,
  itemVariants,
} from 'src/utils/framer-motion';
import { motion } from 'framer-motion';
import { PORTFOLIO_CARDS, SERVICE_CARDS } from 'src/constants/services';
import Orbit from 'src/components/Orbit';
import PortfolioCard from 'src/components/PortfolioCard';

const Landing = () => {
  return (
    <Layout>
      {/* Hero section */}
      <Hero>
        <HeroContainer>
          <TextGroup>
            <HeroHeading {...getScrollRevealProps('left')}>
              {HERO_DETAILS.heading}
            </HeroHeading>

            <HeroText {...getScrollRevealProps('left')}>
              {HERO_DETAILS.text}
            </HeroText>
          </TextGroup>

          <Button asLink href="/projects">
            {HERO_DETAILS.cta}
            <motion.span
              initial={{ x: '-3px' }}
              animate={{ x: '3px' }}
              transition={{
                repeat: Infinity,
                repeatType: 'reverse',
                duration: 0.6,
                restSpeed: 3,
              }}
            >
              <RightArrow />
            </motion.span>
          </Button>

          <HeroImages>
            <Orbit />
            {/* {HERO_IMAGES.map((image) => (
              <HeroImagesLabelWrapper>
                <HeroImagesLabel>
                  <p>W3lco</p>
                </HeroImagesLabel>
                <HeroImage
                  key={image.alt}
                  src={image.src}
                  alt={image.alt}
                  {...getScrollRevealProps("right")}
                />
              </HeroImagesLabelWrapper>
            ))} */}
          </HeroImages>
        </HeroContainer>
      </Hero>

      <PortfolioContainer>
        <PortfolioText>
          <ServiceHeadingGroup {...getScrollRevealProps('right')}>
            <PortfolioHeading>{PORTFOLIO_DETAILS.heading}</PortfolioHeading>
          </ServiceHeadingGroup>
        </PortfolioText>
        <PortfolioCardsWrapper>
          {PORTFOLIO_CARDS.map((portfolio, i) => (
            <>
              <PortfolioCard portfolio={portfolio} />
            </>
          ))}
        </PortfolioCardsWrapper>
      </PortfolioContainer>
      {/* Services section */}
      <Services>
        <ServicesText>
          <ServiceHeadingGroup {...getScrollRevealProps('right')}>
            <ServiceSubHeading>{SERVICES_DETAILS.subHeading}</ServiceSubHeading>
            <ServiceHeading>{SERVICES_DETAILS.heading}</ServiceHeading>
          </ServiceHeadingGroup>
          {/* 
          <ServicesIntro {...getScrollRevealProps("right")}>
            {SERVICES_DETAILS.text}
          </ServicesIntro> */}
        </ServicesText>

        <ServiceCards>
          <Swiper {...ServicesSlideProps}>
            {SERVICE_CARDS.map((service, i) => (
              <SwiperSlide key={i}>
                <ServiceCard service={service} />
              </SwiperSlide>
            ))}
          </Swiper>
        </ServiceCards>

        <ServiceBanner>
          <ServiceBannerImg
            src={SERVICES_DETAILS.banner.image}
            alt={SERVICES_DETAILS.banner.heading}
          />

          <ServiceBannerText>
            <ServiceBannerHeading {...getScrollRevealProps('left')}>
              {SERVICES_DETAILS.banner.heading}
            </ServiceBannerHeading>

            <ServiceBannerList
              variants={containerVariants}
              initial="hidden"
              whileInView="visible"
            >
              {SERVICES_DETAILS.banner.services.map((service, i) => (
                <ListItem key={i} variants={itemVariants}>
                  <DoubleCheckMark />
                  {service}
                </ListItem>
              ))}
            </ServiceBannerList>
          </ServiceBannerText>
        </ServiceBanner>
      </Services>

      {/* TO BE ADDED LATER... */}

      {/* <Testimonials>
        <HeadingGroup
          heading={TESTIMONIAL_DETAILS.heading}
          subheading={TESTIMONIAL_DETAILS.subheading}
        />

        <TestimonialGrid>
          <AvatarGroup
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            {TESTIMONIAL_DETAILS.avatars.slice(0, 3).map((avatar, i) => (
              <AvatarImage
                key={i}
                src={avatar.image}
                data-left
                variants={itemVariants}
              />
            ))}
          </AvatarGroup>

          <TestimonialSlider>
            <Swiper {...TestimonialSlideProps}>
              {TESTIMONIAL_CARDS.map((testimonial, i) => (
                <SwiperSlide key={i}>
                  <TestimonialCard details={testimonial} />
                </SwiperSlide>
              ))}

              <SliderControl />
            </Swiper>
          </TestimonialSlider>

          <AvatarGroup
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            {TESTIMONIAL_DETAILS.avatars.slice(3, 6).map((avatar, i) => (
              <AvatarImage
                key={i}
                src={avatar.image}
                data-right
                variants={itemVariants}
              />
            ))}
          </AvatarGroup>
        </TestimonialGrid>
      </Testimonials> */}
    </Layout>
  );
};

export default Landing;
