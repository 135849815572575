import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import Newsletter from '../Newsletter';
import ServiceRequest from '../ServiceRequest';
import PartnerSection from '../Partners';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isServiceRequestPage = location.pathname === '/services/request';

  return (
    <>
      <Header />
      <main>
        {children}
        {isServiceRequestPage ? false : <ServiceRequest />}
        <Newsletter />
        <PartnerSection />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
